
import LazyLoad from "vanilla-lazyload";
import ScrollOut from "scroll-out";
import simpleParallax from 'simple-parallax-js'
import Swiper from 'swiper';
import bodymovin from 'lottie-web';
import {MatchHeight} from 'js-match-height';



// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const el1 = new MatchHeight('.list-wrapper div h3');
    const el2 = new MatchHeight('.list-wrapper div ul');

    fadeInPage();

    // Page transition functionality
    function fadeInPage() {
        if (!window.AnimationEvent) { return; }
        var fader = document.getElementById('fader');
        fader.classList.add('fade-out');
    }

    if (!window.AnimationEvent) { return; }

    var anchors = document.querySelectorAll('.fade-btn');
    
    for (var idx=0; idx<anchors.length; idx+=1) {

        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }

        anchors[idx].addEventListener('click', function(event) {
                var fader = document.getElementById('fader'),
                    anchor = event.currentTarget;
                
                var listener = function() {
                    window.location = anchor.href;
                    fader.removeEventListener('animationend', listener);
                };
                fader.addEventListener('animationend', listener);
                
                event.preventDefault();
                fader.classList.add('fade-in');
        });  
    }    

    window.addEventListener('pageshow', function (event) {
        if (!event.persisted) {
            return;
        }
        var fader = document.getElementById('fader');
        fader.classList.remove('fade-in');
    });     

    // Parallax images
    var images = document.querySelectorAll('.parallax-overlay');
    new simpleParallax(images, {
        overflow: true,
        delay: .5,
        transition: 'cubic-bezier(0,0,0,1)'
    });

    var imageA = document.querySelectorAll('.parallax-inset');
    new simpleParallax(imageA);

    // Change horizontal text movement depending on browser width
    var moveRightText = document.querySelectorAll('.move-right');
    var moveLeftText = document.querySelectorAll('.move-left');

    if(window.innerWidth < 1024){
        //console.log('narrow');

        for(let moveRight of moveRightText) {
            moveRight.setAttribute('data-rateX', '0.1');
        }

        for(let moveLeft of moveLeftText) {
            moveLeft.setAttribute('data-rateX', '-0.1');
        }
    }

    window.addEventListener("resize", function(){
        if(window.innerWidth < 1024){
            //console.log('narrow');

            for(let moveRight of moveRightText) {
                moveRight.setAttribute('data-rateX', '0.1');
            }

            for(let moveLeft of moveLeftText) {
                moveLeft.setAttribute('data-rateX', '-0.1');
            }

       } else {
            //console.log('wide');

            for(let moveRight of moveRightText) {
                moveRight.setAttribute('data-rateX', '0.3');
            }

            for(let moveLeft of moveLeftText) {
                moveLeft.setAttribute('data-rateX', '-0.3');
            }
       }
    });

    // Initiate animated text
    ScrollOut({ 
        once: true,

        onShown: function() {
            window.addEventListener('scroll', function() {

                const target = document.querySelectorAll('.scroll-item');

                var index = 0, length = target.length;
                for (index; index < length; index++) {
                    var pos = window.pageYOffset * target[index].dataset.rate;

                    if(target[index].dataset.direction === 'vertical') {
                        target[index].style.transform = 'translate3d(0px,'+pos+'px, 0px)';
                    } else {
                        var posX = window.pageYOffset * target[index].dataset.ratex;
                        var posY = window.pageYOffset * target[index].dataset.ratey;
                        
                        target[index].style.transform = 'translate3d('+posX+'px, '+posY+'px, 0px)';
                    }
                }
            });
        }
    });

    // LAZYLOAD
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // OPEN MENU
    const navTrigger = document.querySelector('.js-open-nav');
    const overlay = document.getElementById("overlay");
    const projectWrapper = document.getElementById('app');

    if(navTrigger){ 
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('js-is-open');
            overlay.classList.toggle('show');
            projectWrapper.classList.toggle('js-is-open');
        }, false);
    }

    // SWIPER
    var swiper = new Swiper('.swiper-container', {

      grabCursor: 'true',
      slidesPerView: 1,
      spaceBetween: 30,

      breakpoints: {
        768: {
          spaceBetween: 64,
        },
      },
        
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    // OVERLAY FUNCTIONALITY
    const overlayTriggers = document.querySelectorAll('.js-open-overlay');
    const overlayTeam = document.getElementById('overlay-team');
    const close = document.getElementById('js-close-modal');
    const modal = document.querySelector('.js-modal');
    const app = document.getElementById('app');

    if(overlayTriggers.length){ 
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayTeam.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                projectWrapper.style.position = 'fixed';
                projectWrapper.style.top = `-${scrollY}`;
                const slideNumber = this.getAttribute('data-position');

                swiper.slideTo(slideNumber);
            });
        }

        close.addEventListener('click', function(){
            overlayTeam.classList.remove("show");

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayTeam.addEventListener('click', () => {
            overlayTeam.classList.remove('show');
            
            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            //console.log('click');
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    } 

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    // Job vacancies
    const currentVacancies = document.querySelector(".current-vacancies");
    const BambooHR = document.querySelector("#BambooHR-container");
    if(currentVacancies) {
        currentVacancies.addEventListener("click", () => {
            BambooHR.scrollIntoView({ behavior: 'smooth' });
        });
    }

})